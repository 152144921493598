import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from 'axios';

import { config } from 'config';
import { getLocalisedIsoCountry } from 'utils/localization';
import { ApiBase } from 'apis/api';
import { handleError, is404Error } from 'utils/error';
import { Quiz } from 'types/quiz';
import { setProjectConfig } from './actions';
import test from './test.json';

export const fetchProjectConfig =
  (funnel: string) => async (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    const url = 'content/en';
    let response;
    try {
      const ApiBase = axios.create({
        baseURL: config.BASE_URL,
        params: {
          api_token: config.API_TOKEN,
          p_locale: getLocalisedIsoCountry().toLowerCase(),
        },
      });

      response = await ApiBase.get(`${url}/${funnel}`);
    } catch (e) {
      handleError(e as Error);

      if (is404Error(e)) {
        response = await ApiBase.get(`${url}/${Quiz.Main}`);
      }
    }

    dispatch(setProjectConfig(response?.data?.data?.json_data));
    // dispatch(setProjectConfig(test));
  };
